import { createAsyncThunk } from '@reduxjs/toolkit';

import { instance } from '../../shared/services/app/instance';
import { setToken } from '../../shared/services/app/instance';

import * as toasty from '../../shared/toastify/toastify';

const fetchAllChatsExpert = createAsyncThunk(
  'chats/experts/fetch',
  async (params, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.get('/chats/expert/all');
      return response.data;
    } catch (error) {
      toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const fetchOneExpertChat = createAsyncThunk(
  'oneExpertChat/fetch',
  async ({ _id, page, limit }, thunkApi) => {
    // console.log(_id, page, limit);

    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.get(`/chats/expert/${_id}`, {
        params: { page, limit },
      });
      return response.data;
    } catch (error) {
      // toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const sendMessageExpert = createAsyncThunk(
  'sendMessageExpert/post',

  async ({ _id, dataBody }, thunkApi) => {
    const state = thunkApi.getState();
    // console.log(dataBody, 'dataBody');
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.post(
        `/chats/expert/${_id}/messages`,
        dataBody,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      // toasty.toastSuccess('Update successfully');
      return response.data.data;
    } catch (error) {
      toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const chatsOperations = {
  fetchAllChatsExpert,
  fetchOneExpertChat,
  sendMessageExpert,
};

export default chatsOperations;
