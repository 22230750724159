import { createSlice } from '@reduxjs/toolkit';
import chatsOperations from './chats-operations';
import errorCodes from '../../shared/errorCodes/errorCodes';

const handlePending = state => {
  state.isLoading = true;
};

const handleRejected = (state, action) => {
  state.isLoading = false;
  const errorCode = action.payload;
  state.error = errorCodes[errorCode] || errorCode;
};

const chatsSlice = createSlice({
  name: 'chats',
  initialState: {
    allExpertsChats: [],
    allCustomersChats: [],
    oneExpertChat: {},
    oneCustomerChat: {},
    isLoading: false,
    error: null,
    total: null,
  },
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    chatsClearError: state => {
      state.error = null;
    },
  },

  extraReducers: builder => {
    builder
      .addCase(chatsOperations.fetchAllChatsExpert.pending, handlePending)
      .addCase(
        chatsOperations.fetchAllChatsExpert.fulfilled,
        (state, action) => {
          state.chatsExpertList = action.payload.data;
          state.total = action.payload.total;
          state.isLoading = false;
          state.error = null;
        }
      )
      .addCase(chatsOperations.fetchAllChatsExpert.rejected, handleRejected)

      .addCase(chatsOperations.fetchOneExpertChat.pending, handlePending)
      .addCase(
        chatsOperations.fetchOneExpertChat.fulfilled,
        (state, action) => {
          console.log('Payload:', action.payload.data.messages);
          state.oneExpertChat = action.payload.data;
          // state.total = action.payload.total;
          state.isLoading = false;
          state.error = null;
        }
      )
      .addCase(chatsOperations.fetchOneExpertChat.rejected, handleRejected)

      .addCase(chatsOperations.sendMessageExpert.pending, handlePending)
      .addCase(chatsOperations.sendMessageExpert.fulfilled, (state, action) => {
        // state.allExpertsChats.push(action.payload);
        state.oneExpertChat?.messages.push(action.payload);
        state.isLoading = false;
        state.error = null;
      })
      .addCase(chatsOperations.sendMessageExpert.rejected, handleRejected);
  },
});

export const { setError, expertsClearError } = chatsSlice.actions;

export default chatsSlice.reducer;
