import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer from './auth/auth-slice';
import adminUsersReducer from './admin/admin-users-slice';
import expertsReducer from './experts/experts-slice';
import usersReducer from './users/users-slice';
import reviewsReducer from './reviews/reviews-slice';
import consultationsReducer from './consultations/consultations-slice';
import complaintsReducer from './complaints/complaints-slice';
import logsReducer from './logs/logs-slice';
import partnersReducer from './partners/partners-slice';
import chatsReducer from './chats/chats-slice';


const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['token'],
};

const persistedAuthReducer = persistReducer(persistConfig, authReducer);

const rootReducer = combineReducers({
  auth: persistedAuthReducer,
  adminUsers: adminUsersReducer,
  experts: expertsReducer,
  users: usersReducer,
  reviews: reviewsReducer,
  consultations: consultationsReducer,
  complaints: complaintsReducer,
  logs: logsReducer,
  partners: partnersReducer,
  chats: chatsReducer,
});

export default rootReducer;
