import { createAsyncThunk } from '@reduxjs/toolkit';

import { instance } from '../../shared/services/app/instance';
import { setToken } from '../../shared/services/app/instance';

import * as toasty from '../../shared/toastify/toastify';

const fetchAllPartners = createAsyncThunk(
  'allPartners/fetch',
  async (_, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.get('/partners');
      return response.data.data;
    } catch (error) {
      // toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const fetchAllPartnersByQuery = createAsyncThunk(
  'allPartnersByQuery/fetch',
  async (params, thunkApi) => {
    const { page, limit } = params;

    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.get('/partners', {
        params: {
          page,
          limit,
        },
      });
      return response.data.data;
    } catch (error) {
      // toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const fetchOnePartner = createAsyncThunk(
  'onePartner/fetch',
  async (_id, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.get(`/partners/${_id}`);
      return response.data.data;
    } catch (error) {
      // toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const addNewPartner = createAsyncThunk(
  'addNewPartner/post',
  async (formData, thunkApi) => {
    // const { page, limit, name, logo } = params;
    const state = thunkApi.getState();
    // console.log(formData);
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.post(`/partners`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data.data;
    } catch (error) {
      toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const updatePartner = createAsyncThunk(
  'updatePartner/put',

  async ({ _id, dataBody }, thunkApi) => {
    const state = thunkApi.getState();

    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.put(`/partners/${_id}`, dataBody, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toasty.toastSuccess('Update successfully');
      return response.data.data;
    } catch (error) {
      toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const connectExpert = createAsyncThunk(
  'connectExpert/post',
  async ({ _id, expertIds }, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.post(`/partners/${_id}`, { expertIds });

      return response.data.data;
    } catch (error) {
      toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const deletePartner = createAsyncThunk(
  'deletePartner/delete',
  async (_id, thunkApi) => {
    // console.log(_id);
    const state = thunkApi.getState();

    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.delete(`/partners/${_id}`, {});
      return response.data.data;
    } catch (error) {
      toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const deleteExpertFromPartner = createAsyncThunk(
  'deleteExpertFromPartner/delete',
  async ({ _id, expertId }, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.delete(`/partners/${_id}/expert`, {
        data: { expertId },
      });
      return response.data;
    } catch (error) {
      // toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const partnersOperations = {
  fetchAllPartners,
  fetchAllPartnersByQuery,
  fetchOnePartner,
  addNewPartner,
  updatePartner,
  deletePartner,
  connectExpert,
  deleteExpertFromPartner,
};

export default partnersOperations;
